<template>
  <section class="list-require-stock-slip-selected-modal-table">
    <table class="table table-bordered-list table-hover col-md-12">
      <thead>
        <tr>
          <th class="text-center tdCode">Kho</th>
          <th class="text-center tdCustomer">Mã phiếu</th>
          <th class="text-center tdProductName">Sản phẩm</th>
          <th class="text-center tdQuantity">SL</th>
          <th class="text-center tdNote">Trạng thái</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(requireStockSlip, index) in requireStockSlips">
          <tr
            :key="requireStockSlip.id"
            @mouseover="hoverRow"
            @mouseleave="outRow"
            :data="`row_${requireStockSlip.id}`"
          >
            <td
              colspan="10"
              style="
              padding: 5px !important;
              border-bottom: none !important;
            "
            >
              <div
                class="d-flex"
                style="font-size: 0.87rem"
              >
                <span class="mr-4">Ngày tạo phiếu:
                  <span class="text-primary">
                    {{ formatDate(requireStockSlip.createdAt) }}
                  </span>
                </span>

                <span class="mr-4 font-weight-bolder">Ngày chuyển kho:
                  <span class="text-primary">
                    {{ formatDate(requireStockSlip.transferDate) }}
                  </span>
                </span>

                <span class="mr-4">
                  <span class="mr-1">Người tạo:</span>
                  <span v-if="requireStockSlip.createdBy">
                    {{ requireStockSlip.createdBy }}
                  </span>
                  <span
                    v-else
                    class="text-danger"
                  >
                    Chưa được gán
                  </span>
                </span>

                <span class="mr-4">
                  <span class="mr-1">Người duyệt:</span>
                  <span v-if="requireStockSlip.approveByName">
                    {{ requireStockSlip.approveByName }}
                  </span>
                  <span
                    v-else
                    class="text-danger"
                  >
                    Chưa được gán
                  </span>
                </span>
              </div>
            </td>
          </tr>
          <template v-for="(productInLineItem, j) in requireStockSlip.listDetail">
            <tr
              :key="`${index + j}_${productInLineItem.id}`"
              @mouseover="hoverBill"
              @mouseleave="outBill"
              :data="`row_${requireStockSlip.id}`"
            >
              <td
                :rowspan="requireStockSlip.listDetail.length"
                v-if="j === 0"
                class="tdTextAlign tdCode"
              >
                <div
                  class="orderCode font-weight-bolder"
                  style="cursor: pointer"
                >
                  <span>{{ requireStockSlip.sourceStoreShortName }}</span>
                  <br />
                  <i
                    style="font-size: 1rem; color: blue"
                    class="flaticon2-right-arrow"
                  ></i>
                  <br />
                  <span>{{ requireStockSlip.desStoreShortName }}</span>
                </div>
              </td>
              <td
                :rowspan="requireStockSlip.listDetail.length"
                v-if="j === 0"
                class="tdTextAlign"
              >
                <div class="orderCode text-center">
                  <p
                    style="cursor: pointer"
                    class="font-weight-bold text-primary"
                    @click="navigateToDetailBill(requireStockSlip.code)"
                  >
                    {{ requireStockSlip.code }}
                  </p>
                  <p>
                    Từ phiếu CK: {{ requireStockSlip.relateStockCode }}
                  </p>
                </div>
              </td>
              <td class="tdTextAlign tdProductName wrapword">
                <span style="font-size: 11px">{{ productInLineItem.barCode }}</span>
                <br />
                <span style="font-size: 12px">({{ productInLineItem.productCode }})</span>
                <br />
                <span class="font-weight-bold">{{ productInLineItem.productName }}</span>
              </td>
              <td class="tdTextAlignPrice tdQuantity">
                {{ productInLineItem.quantity }}
              </td>
              <td
                class="tdTextAlign tdStatus text-center wrapword"
                :rowspan="requireStockSlip.listDetail.length"
                v-if="j === 0"
                @mouseover="hoverPrice = true"
                @mouseleave="hoverPrice = false"
              >
                <div v-if="requireStockSlip.statusValidate === status.LOADING">
                  <b-spinner
                    small
                    variant="primary"
                    class="mr-2"
                  ></b-spinner>
                  <span class="text-primary">Đang xử lí...</span>
                </div>
                <div v-else>
                  <div
                    class="d-table-cell align-middle"
                    style="width: 10%"
                  >
                    <i
                      v-b-tooltip.hover
                      :title="htmlGetStatusValidate(requireStockSlip, 'title')"
                      :class="htmlGetStatusValidate(requireStockSlip, 'class')"
                    ></i>
                  </div>
                  <div
                    class="d-block align-middle pb-1 pl-2"
                    style="width: 90%"
                  >
                    <div
                      v-for="(items, i) in requireStockSlip.errors"
                      :key="i"
                    >
                      <span class="text-danger">{{ items }}</span>
                    </div>
                  </div>
                  <div
                    class="d-table-cell align-middle pb-1 pl-2"
                    style="width: 90%"
                  >
                    {{ requireStockSlip.statusValidateName }}
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </section>
</template>

<script>
import {
  BILL_ITEM_TYPE,
  AMOUNT_TYPE,
  PRODUCT_TYPE,
  VAT_AMOUNT,
} from '@/utils/enum';
import sumBy from 'lodash/sumBy';
import { convertPrice, currencyMask, formatDate } from '@/utils/common';
import { ONE_HUNDRED } from '@/utils/constants';
import { tableHoverMixin } from '@/view/mixins';

const STATUS_VALIDATE = {
  INVALID: 0,
  NOT_ALLOW_INCLUDE_LIKE_NEW_PRODUCT: 1,
  VALID: 2,
  READY: 3,
  LOADING: 4,
};

export default {
  name: 'ListRequireStockSelectedModalTable',
  mixins: [tableHoverMixin],
  props: {
    requireStockSlips: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
  },
  components: {},

  data() {
    return {
      mask: currencyMask,
      BILL_ITEM_TYPE,
      AMOUNT_TYPE,
      PRODUCT_TYPE,
      checked: false,
      selectedProduct: [],
      unSelectedProduct: [],
      productKCT: {},
      status: STATUS_VALIDATE,
    };
  },
  computed: {
    sumBill() {
      const details = [...this.requireStockSlips];
      if (!details.length) return 0;
      const sumBill = sumBy(
        details,
        (item) => item.totalAmount - item.allotmentDiscountAmount,
      );
      return convertPrice(sumBill);
    },
    sumTotalAmount() {
      const details = [...this.requireStockSlips];
      if (!details.length) return 0;
      const sumBill = sumBy(details, 'totalAmount');
      return convertPrice(sumBill);
    },
    sumQuantity() {
      const details = [...this.requireStockSlips];
      if (!details.length) return 0;
      const sumBill = sumBy(details, 'quantity');
      return convertPrice(sumBill);
    },
  },
  methods: {
    formatDate,
    convertPrice,
    htmlGetStatusValidate(item, type) {
      let name = '';
      switch (item.statusValidate) {
        case STATUS_VALIDATE.READY: {
          name =
            type === 'class'
              ? 'far fa-clock text-primary'
              : 'Sẵn sàng phát hành';
          break;
        }
        case STATUS_VALIDATE.NOT_ALLOW_INCLUDE_LIKE_NEW_PRODUCT: {
          name =
            type === 'class'
              ? 'fas fa-exclamation-circle text-warning'
              : 'Sản phẩm cũ không hợp lệ';
          break;
        }
        case STATUS_VALIDATE.VALID: {
          name =
            type === 'class'
              ? 'fas fa-check-circle text-success'
              : 'Sẵn sàng phát hành';
          break;
        }
        case STATUS_VALIDATE.INVALID: {
          name =
            type === 'class'
              ? 'fas fa-times-circle text-danger'
              : 'Không hợp lệ';
          break;
        }
        default: {
          name =
            type === 'class'
              ? 'far fa-clock text-primary'
              : 'Sẵn sàng phát hành';
          break;
        }
      }
      return name;
    },
    hasInvalidDatas({ invalidDatas = [] }) {
      return invalidDatas.length;
    },
    getClassStatus({ invalidDatas = [], push }) {
      if (this.hasInvalidDatas({ invalidDatas, push })) {
        return {
          title: 'Dữ liệu không hợp lệ để đẩy',
          class: 'fas fa-times-circle text-danger icon-size',
        };
      }
    },
    calculateDiscountAmount({
      productPrice,
      discountAmount,
      discountType,
      quantity = 1,
    }) {
      if (discountType === AMOUNT_TYPE.PERCENT) {
        return productPrice * quantity * (discountAmount / ONE_HUNDRED);
      }
      return discountAmount;
    },
    getVatName(product) {
      if (product.vatAmount === VAT_AMOUNT.PERCENT_KCT) return 'KCT';
      return `${product.vatAmount}%`;
    },
    bindingAttrByType({ billItemType }) {
      const mappingType = {
        [BILL_ITEM_TYPE.PRODUCT_BONUS]: {
          title: 'Quà tặng',
          class: 'fas fa-gift mr-1 font-size-sm',
          style: 'color: #2e7d32; font-size: 1rem',
        },
        [BILL_ITEM_TYPE.PRODUCT_PROMTION]: {
          title: 'Sản phẩm bán kèm',
          class: 'fas fa-tags mr-2 font-size-sm text-dark-75',
        },
      };
      return mappingType[billItemType] || '';
    },
    selectedVat({ isChecked }) {
      return !!isChecked;
    },
    totalPriceOfProduct(product) {
      if (!product || !Object.keys(product)) return 0;

      const totalAmount = product.totalAmount || 0;
      const allotmentDiscountAmount = product.allotmentDiscountAmount || 0;

      const newAmount = totalAmount - allotmentDiscountAmount;

      return this.convertPrice(newAmount);
    },
  },
};
</script>

<style lang="scss" >
.custom-pagination {
  .page-item.disabled {
    cursor: not-allowed;
    &.bv-d-xs-down-none {
      .page-link {
        margin: 0.35rem 0.25rem;
        text-align: center;
      }
    }
  }
}
.list-require-stock-slip-selected-modal-table {
  .table-bordered-list th {
    border: 0.5px solid #d2d4d6 !important;
    background-color: #e0e0e0;
  }

  .table-bordered-list td {
    border: 0.5px solid #f3f3f3;
    border-bottom: 0.5px solid #adadad;
  }
  .wrapword {
    white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
    white-space: -webkit-pre-wrap; /* Chrome & Safari */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    white-space: pre-wrap; /* CSS3 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    word-break: break-all;
    white-space: normal;
  }

  .table th,
  .table td {
    vertical-align: middle;
  }
  .tdCenter {
    vertical-align: left !important;
  }
  .tdTextAlign {
    vertical-align: middle;
  }
  .tdTextAlignPrice {
    text-align: right !important;
    vertical-align: middle;
  }

  .tdCode {
    width: 10%;
  }
  .tdStore {
    width: 6.8%;
  }
  .tdCustomer {
    width: 35%;
  }
  .tdProductName {
    width: 35%;
  }
  .tdProductPrice {
    width: 3%;
  }
  .tdQuantity {
    width: 3%;
  }
  .tdDiscountItem {
    width: 5%;
  }
  .tdTotalAmount {
    width: 8%;
  }
  .tdNote {
    width: 15%;
  }
  .tdStatus {
    width: 15%;
  }

  .icon-size {
    font-size: 1rem;
  }

  table tbody td {
    vertical-align: middle;
  }

  .date-style {
    display: flex;
    align-items: center;
  }

  .dashed {
    border: 1px dashed #464e5f;
    border-style: dashed none none;
  }
}
</style>
